/* eslint-disable @typescript-eslint/naming-convention */

import { NativeMessageData } from '../Entities/NativeMessageTypes.entity';
import { NativeScreenType } from '../Entities/NativeScreenType.entity';

export const NATIVE_SCREEN_TYPE_MAP: Record<Exclude<NativeScreenType, 'fallback'>, RegExp> = {
  homepage: /^(\/(uk|fr))(\/?)$/,
  plp: /^(\/(uk|fr))?\/autos/,
  pdp: /^(\/(uk|fr))?\/auto\/(?!vehicle-history)/,
  favourites: /^(\/(uk|fr))?\/favourites/,
  account: /^(\/(uk|fr))?\/account/,
  promotions: /^(\/(uk|fr))?\/promotions/,
  leasing: /^(\/(uk|fr))?\/leasing/,
  sell: /^(\/(uk|fr))?\/value-my-car/,
  external: /^http/,
};

export const NATIVE_SCREEN_TITLE_MAP: Record<string, RegExp> = {
  heycar: /^(\/(uk|fr))(\/?)$/,
  'Loading results...': /^(\/(uk|fr))?\/autos/,
  'Loading vehicle...': /^(\/(uk|fr))?\/auto\/(?!vehicle-history)/,
  Favourites: /^(\/(uk|fr))?\/favourites/,
  Promotions: /^(\/(uk|fr))?\/promotions/,
  'Value my car': /^(\/(uk|fr))?\/value-my-car/,
  Leasing: /^(\/(uk|fr))?\/leasing/,
  'My account': /^(\/(uk|fr))?\/account/,
};

export const NATIVE_LOADER_MAP: Record<string, RegExp> = {
  1: /^(\/(uk|fr))?\/auto(s)?/,
};

export const sanitizePath = (pathname: string): string => {
  if (!pathname) return '';

  let sanitizedPath = pathname;

  if (sanitizedPath.startsWith('http') && sanitizedPath.includes('heycar.com')) {
    sanitizedPath = sanitizedPath.replace('https://heycar.com', '');
  }
  if (!sanitizedPath.startsWith('/') && !/^(https?|mailto|tel|#)/.test(sanitizedPath)) {
    sanitizedPath = `/${sanitizedPath}`;
  }
  return sanitizedPath;
};
export function createNativeMessage(sanitizedHref: string): NativeMessageData {
  const screenType = getScreenTypeFromPath(sanitizedHref);
  const updateHeader = getScreenTitleFromPath(sanitizedHref);

  const message: NativeMessageData = {
    updateHeader: updateHeader,
  };

  if (sanitizedHref.startsWith('tel:')) {
    return {
      triggerAction: {
        action: 'call',
        payload: sanitizedHref.substring(4),
      },
    };
  } else {
    return {
      navigate: {
        url: sanitizedHref,
        screenType: screenType,
      },
      updateHeader: getScreenTitleFromPath(sanitizedHref),
    };
  }

  return message;
}

export const getScreenTypeFromPath = (pathname: string): NativeScreenType => {
  for (const [screenType, regex] of Object.entries(NATIVE_SCREEN_TYPE_MAP)) {
    if (regex.test(pathname)) {
      return screenType as NativeScreenType;
    }
  }

  return 'fallback';
};

export const checkShouldHideLoader = (pathname: string) => {
  for (const [_, regex] of Object.entries(NATIVE_SCREEN_TYPE_MAP)) {
    if (regex.test(pathname)) return false;
  }

  return true;
};

export const getScreenTitleFromPath = (pathname: string): string => {
  for (const [screenTitle, regex] of Object.entries(NATIVE_SCREEN_TITLE_MAP)) {
    if (regex.test(pathname)) {
      return screenTitle as NativeScreenType;
    }
  }

  return ' ';
};
