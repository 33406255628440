'use client';

import { NativeMessage, NativeMessageData } from '../Entities/NativeMessageTypes.entity';

export const sendNativeMessage = (messageData: NativeMessageData & { source?: 'native' }) => {
  if (typeof window === 'undefined') return;

  const newMessage = JSON.stringify({
    source: messageData.source || 'web',
    data: messageData,
  } as NativeMessage);

  window?.postMessage?.(newMessage);

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView?.postMessage?.(newMessage);
  }
};
